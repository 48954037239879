<nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row"  style="background-color:white">
  <div
    class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center"
  >
    <a class="navbar-brand" routerLink="/home">
      <div class="row" style="color:black">
       
        <div class="col-8">
          <img src="{{config()?.logo }}" alt="Logo" id="logoImg2" style="max-height: 7vh;" />
        </div>
        <div class="col-4">
          <!-- <img src="./assets/img/logoAppturno.png" alt="Logo" id="logoImg1" /> -->
        </div>
      </div>
    </a>
    <a class="navbar-brand brand-logo-mini" routerLink="/">
      <h4 style="color:black;" class="mt-3 font-weight-bold"></h4>
    </a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center">
    <ul class="navbar-nav navbar-nav-right">
    
      <li  style="cursor: pointer;">
        
        <a style="color: #0863a8;" (click)="messageSwal()"><i class="fa fa-sign-out"></i></a>
      </li>
     
     
    </ul>
    
  </div>
</nav>


