<div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="d-flex justify-content-center">
          <div
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            style="margin-top: 20vh;"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title text-center mb-5">Iniciar sesión</h5>
                <form
                  [formGroup]="formLogin"
                  (ngSubmit)="onLoginEmailPassword()"
                  class="mb-3"
                >
                  <div class="form-group  mb-3">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Correo electrónico</label
                    >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text mr-1">
                          <i class="fa fa-user"></i>
                        </div>
                      </div>
                      <input
                        type="email"
                        formControlName="email"
                        class="form-control"
                        placeholder="Email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        autofocus
                      />
                      <span
                        *ngIf="submitted && f.email.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.email.invalid" class="alert alert-danger">
                          El correo electrónico es inválido. Por ejemplo
                          <span class="font-weight-bold">email@mail.com</span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="form-group  mb-3">
                    <label class="sr-only" for="inlineFormInputGroup"
                      >Contraseña</label
                    >
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fa fa-lock"></i>
                        </div>
                      </div>
                      <input
                        type="password"
                        formControlName="password"
                        class="form-control"
                        placeholder="Password"
                        [ngClass]="{
                          'is-invalid': submitted && f.password.errors
                        }"
                      />
                      <div
                        *ngIf="submitted && f.password.errors"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.password.errors.required"
                          class="alert alert-danger"
                        >
                          Contraseña requerida
                        </div>
                        <div
                          *ngIf="f.password.errors.minlength"
                          class="alert alert-danger"
                        >
                          Contraseña muy corta.
                          <span class="font-weight-bold"
                            >Min {{ passMinLegth }} caracteres</span
                          >.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      [disabled]="loading"
                      class="btn btn-primary btn-block"
                    >
                      Entrar
                      <img
                        *ngIf="loading"
                        src="../../../../assets/img/loading.svg"
                      />
                    </button>
                  </div>
                </form>
  
                <div class="row justify-content-end mr-1">
                  <a (click)="resetPassword()" class="reset"
                    >Recuperar contraseña</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
