<div class="modal-content">
    <div class="modal-body">
        <form #newTurnForm="ngForm">
            <div class="form-row mt-0">
                <div class="col-md-12">
                    <img class="rounded mx-auto d-block" src="https://www.gifss.com/informatica/impresoras/01.gif">
                    <p class="ml-3 mt-2 text-center">Generando turno ...</p>
                </div>
                <div class="col-md-12" style="margin: 0px !important; margin-top: -20px; padding: 0 !important;" id="print-section" hidden>
                   
                    <div style="text-align: center; margin: 0px !important; ">
                        <img src="../../../../assets/img/logo.jfif" width="50% !importante" style="margin: 0px !important; "><br>
                        <small style="font-size:12px; margin-top: 10px;">
                            {{ hoy | date: 'MMM d y h:mm:ss a' | titlecase }}</small>
                        <p style="margin: 0 !important; padding-left: 0 !important; padding-right: 0 !important;">Bienvenido Al Punto Empresario </p>
                        <h5 style="margin: 0 !important;">{{user?.zone?.name}}</h5>
                        <p style="margin: 0 !important;">Su numero de ticket es </p>
                        <h1 style="margin: 0 !important;"> {{ title }}</h1>
                        <h5 style="margin: 0 !important;">Servicio : {{ service | titlecase}}</h5>
                        <h5 style="margin: 0 !important;">Nit: {{ nit }}</h5>
                        <h5 style="margin: 0 !important;">Concedido a  {{ client? client : 'No registrado' }}</h5>
                        <h5 style="margin: 0 !important;">Nit: {{ nit }}</h5>
                        <h6 style="font-size: 13px; margin-top: 0 !important; margin-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important;">Por favor, espere en sala mientras es llamado... </h6>

                        <h6 style="font-size: 13px; margin-top: 15px !important; margin-bottom: 0 !important; padding-left: 0 !important; padding-right: 0 !important;">La información aqui tratada se encuentra protegida por nuestra 
                            política de privacidad de datos personales.
                             <br> Puedes consultarla escaneando este código </h6>

                        <ngx-qrcode
                        [id]="'qr'" 
                        [elementType]="elementType" 
                        [value]="value"
                        [width]="130"
                        cssClass="aclass"
                        [errorCorrectionLevel]="correctionLevels"
                      ></ngx-qrcode> 
                          <h3 style="margin: 0 !important; margin-top: 15px !important; margin-bottom: 15px !important; padding-left: 0 !important; padding-right: 0 !important;">www.appturno.com <img src="../../../../assets/img/ticket.png" style="width: 35px; margin: 0 !important; padding-left: 0 !important; padding-right: 0 !important;"></h3>
                    </div>
                    
                </div>
                <button id="btnPrint" printSectionId="print-section" (click)="newTurnModal.close('Close click')" ngxPrint
                        hidden>print</button>
            </div>
        </form>
    </div>
</div>