import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-working-day-close',
  templateUrl: './working-day-close.component.html',
  styleUrls: ['./working-day-close.component.scss']
})
export class WorkingDayCloseComponent implements OnInit {
@Input() message = "Hola, sentimos informarle que aún no se encuentra habilitada la jornada para el dia de hoy, por favor diríjase con el funcionario administrador!";
  constructor() { }

  ngOnInit() {
  }

}
