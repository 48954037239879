import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-area',
  templateUrl: './choose-area.component.html',
  styleUrls: ['./choose-area.component.scss']
})
export class ChooseAreaComponent implements OnInit {
  @Input() areas :Array<any> = [];
  @Input() client :any = null;
  @Output() closedAreas: EventEmitter<any> = new EventEmitter<any>();
  @Output() emiterError: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  
  ngOnInit(): void {
  }
  selectArea(area:any) {
    this.closedAreas.emit(area);
  }
  close()
  {
    this.closedAreas.emit({close:true});
  }
}
