import { environment } from "../../environments/environment";

export const Endpoint = {
  // Login
  AUTH: {
    LOGIN_BASE: environment.api.base + "auth",
    LOGIN: environment.api.base + "auth/loginAuto",
    VERIFY_TOKEN: environment.api.base + "auth/verifyToken",
    LOGOUT: environment.api.base + "auth/logout"

  },
  AREA: {
    AREA_BASE: environment.api.base + "areas",
    AREA_WITH_SERVICES: environment.api.base + "areas/areaswithservices",
  },
  CLIENT: {
    BASE :environment.api.base + "clients",
    FIND_OR_CREATE :environment.api.base + "clients/findOrCreateClient",
  },
  TURN: {
    TURN_BASE: environment.api.base + "turns",
    
  },
  WORKING_DAY: {
    BASE: environment.api.base + "working_day",
    TODAY: environment.api.base + "working_day/actions/now",
  },
  TENANCY_CONFIG: {
    BASE: environment.api.base + "tenancyConfig",
    PUBLIC_GET: environment.api.base + "tenancyConfig/get",

  }
  
};
