import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from "@techiediaries/ngx-qrcode";

@Component({
  selector: "app-modal-turn",
  templateUrl: "./modal-turn.component.html",
  styleUrls: ["./modal-turn.component.scss"]
})
export class ModalTurnComponent implements OnInit {
  @ViewChild("newTurnForm") newTurnForm: NgForm | undefined;
  @Input() title: string = "";
  @Input() client: string = "";
  @Input() service: string = "";
  @Input() nit: string = "";
  elementType : NgxQrcodeElementTypes  = NgxQrcodeElementTypes.URL;
  correctionLevels :NgxQrcodeErrorCorrectionLevels = NgxQrcodeErrorCorrectionLevels.LOW;
  value = 'https://www.camarabaq.org.co/wp-content/uploads/2019/01/POLI%CC%81TICA-DE-PRIVACIDAD-DE-DATOS-PERSONALES.pdf';
  public hoy = new Date();
public user = JSON.parse(localStorage.getItem('user')!) ;
  constructor(public newTurnModal: NgbActiveModal) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById("btnPrint")?.click();
    }, 1500);
  }
}
