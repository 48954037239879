import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";
import { HelperService } from "src/app/services/helper.service";
import { Constant } from "src/app/shared/constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  public user: any;
  public visible : boolean = true;
  public version = Constant.VERSION;
  constructor(private auth: AuthService,private router: Router, public helper: HelperService,
    private toastr: ToastrService,) {}

  ngOnInit() {
    this.user = Constant.AUTH.getUser();
    
    
  }
  
  public logout() {
    this.auth.logout().subscribe((res : any) => {
      this.toastr.info("Cerrando sesión..", "", {
        timeOut: 1000
      });
    });
    setTimeout(() => {
      this.router.navigate(["/login"]);
    }, 1200);
  }
  public messageSwal() {
    Swal.fire({
      title: "Pin de Seguridad",
      input: "password",
      inputPlaceholder: "Ingrese pin de acceso para salir",
      inputAttributes: {
        autocapitalize: "off"
      },
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      preConfirm: pin => {
        if (!this.pinValidate(pin)) {
          Swal.showValidationMessage(`Pin incorrecto!`);
          return false;
        } else {
          this.auth.logout().subscribe((res : any) => {
            this.toastr.info("Cerrando sesión..", "", {
              timeOut: 1000
            });
          });
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 1200);
          return true;
        }
      }
    }).then(result => {
      return false;
    });
  }

  private pinValidate(pin : number) {
    const userPin: number = Constant.AUTH.getUser().pin;
    if (pin == userPin) {
      return true;
    }
    return false;
  }
  public getUser() 
  {
  return  Constant.AUTH.getUser();
  }
 
}
