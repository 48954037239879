<!-- <div class="container-fluid body">
    <div class="row">
        <div class="col-md-12 text-center">
            <h3 class="title">Digita tu nit o cédula</h3>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
                <form [formGroup]="form"  autocomplete="off" class="row">
                <div class="col-md-12 form-group">
                            
                            <input
                              type="text"
                              class="form-control ml-1 mr-1 inp-search"
                              placeholder="Digita tu numero de identidad"
                              formControlName="identification"
                              readonly
                              autocomplete="off"
                              [ngClass]="{ 'is-invalid': submitted && f['identification'].errors }"
                              autofocus
                            />
                            <span *ngIf="submitted && f['identification'].errors" class="invalid-feedback">
                              <div *ngIf="f['identification'].invalid" class="alert alert-danger">
                               El campo no tiene el formato correcto, solo numeros y maximo 12 digitos *
                              </div>
                            </span>
                       
                </div>
                <div class="col-md-12" style="display: flex; justify-content: center;">
                    <div class="cont-btns">
                        <div class="cont-btn text-center" *ngFor="let item of [].constructor(9); let i = index">
                            <button class="btn-number" (click)="addDigit(i + 1)"> {{i + 1}}</button>
                           
                        </div>
                        <div class="cont-btn text-center" >
                            <button class="btn-number" (click)="removeDigit()">{{menor}} </button>
                           
                        </div>
                        <div class="cont-btn text-center" >
                            <button class="btn-number" (click)="addDigit(0)">0</button>
                           
                        </div>
                        <div class="cont-btn text-center" >
                            <button class="btn-number" (click)="search()"><i class="fa fa-search" aria-hidden="true"></i></button>
                           
                        </div>
                    </div>
                </div>
                 
            </form>
            
        </div>
    </div>
</div> -->
<div class="row">
    <div class="col">
        <form [formGroup]="form"  autocomplete="off" class="row">
            <div class="col-md-12 form-group">
                        
                        <input
                          type="text"
                          class="form-control ml-1 mr-1 inp-search"
                          placeholder="Digita tu numero de identidad"
                          formControlName="identification"
                          readonly
                          autocomplete="off"
                          [ngClass]="{ 'is-invalid': submitted && f['identification'].errors }"
                          autofocus
                        />
                        
                        <div class="row">
                            <div class="col">
                             <span *ngIf="submitted && f['identification'].errors" class="invalid-feedback">
                                 <div *ngIf="f['identification'].invalid" class="alert alert-danger">
                                  El campo no tiene el formato correcto, solo numeros y maximo 12 digitos *
                                 </div>
                               </span>
                            </div>
                         </div>
            </div>
          
            <div class="col-md-12" style="display: flex; justify-content: center;">
                <div class="cont-btns">
                    <div class="cont-btn text-center" *ngFor="let item of [].constructor(9); let i = index">
                        <button class="btn-number" (click)="addDigit(i + 1)"> {{i + 1}}</button>
                       
                    </div>
                    <div class="cont-btn text-center" >
                        <button class="btn-text" (click)="removeDigit()"
                        style="background: #8D979B;"> Borrar </button>
                       
                    </div>
                    <div class="cont-btn text-center" >
                        <button class="btn-number" (click)="addDigit(0)">0</button>
                       
                    </div>
                    <div class="cont-btn text-center" >
                        <button class="btn-text" (click)="search()" style="background: green;">ok</button>
                       
                    </div>
                </div>
            </div>
             
        </form>
    
    </div>
    <div class="col title" style="align-self: center;" id="TitleBod">
       
           
            <div class="col">
                <H1 class="title">BIENVENIDO</H1>
            </div>
        </div>
        
</div>