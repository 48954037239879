import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {   FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'src/app/services/client.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-search-identification',
  templateUrl: './search-identification.component.html',
  styleUrls: ['./search-identification.component.scss']
})
export class SearchIdentificationComponent implements OnInit {
  @Output() closeSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() emiterError: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public submitted = false;
public menor = "<";
  constructor(private fb: FormBuilder, private clientService : ClientService) {
    this.form = this.fb.group({
      identification: ["", Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(15)]) ]
    });   }

  ngOnInit(): void {
  }
  createForm() {
   
  }
  public get f() {
    return this.form.controls;
  }
  public addDigit(digit:number) {
    let value = this.form.controls['identification'].value;
    value +=  digit;
    this.form.controls['identification'].setValue(value);
    
  }
  public removeDigit() {
    let value = this.form.controls['identification'].value;
    const newValue = value.substring(0, value.length - 1);
    this.form.controls['identification'].setValue(newValue);
  }
  public search() {
    this.submitted = true;
    if(this.form.valid) {
      this.clientService.searchOrCreate(this.form.value.identification).subscribe(
        (res : any) => {
          if (res?.data?.id) {
            this.closeSearch.emit(res.data);
          }else{
            this.emiterError.emit({method: "searc", error : res});
            Swal.fire('Error  obteniendo cliente',  JSON.stringify(res), 'warning')
          }
        },
        (err : any) => {
          this.onFailure(err);
          this.emiterError.emit({method: "searc", error : err});
        } 
      )
    }
  }
  private onFailure(err:any) {
    let errorString = (err.errors && err.errors.length > 0) ? err.errors.toString() : err.error?.message;
    Swal.fire(err.codetext? err.codetext : 'Busqueda de cliente fallida', errorString, 'error'   );
  }
}
