import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { NgbDropdownConfig } from "@ng-bootstrap/ng-bootstrap";

import { Constant } from "../../../shared/constants";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth.service";


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  configApp : any =JSON.parse(localStorage.getItem('config')!);
  public sidebarOpened = false;
  public time = new Date();
  public timer : any;
  public logo : string = "";
  public user: any;
  public username : string = "";
  userTypes : Array<any> = Constant.AUTH.getUserTypes() || [];
  
  
  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector(".sidebar-offcanvas")?.classList.add("active");
    } else {
      document.querySelector(".sidebar-offcanvas")?.classList.remove("active");
    }
  }

  constructor(
    config: NgbDropdownConfig,
    private router: Router,
    private toastr: ToastrService,
    private Auth: AuthService,
  ) {
    config.placement = "bottom-right";
  }

  ngOnInit() {
    
    
    this.user = Constant.AUTH.getUser();
    this.username = this.user?.name.split(" ", 3);
  }
  config(){
    return JSON.parse(localStorage.getItem('config')!);
  }


  public logout() {
   
    this.Auth.logout().subscribe((res:any) => {
      this.toastr.info("Cerrando sesión..", "", {
        timeOut: 1000
      });
    });
    setTimeout(() => {
      this.router.navigate(["/login"]);
    }, 1200);
  }

  public messageSwal() {
    Swal.fire({
      title: "Pin de Seguridad",
      input: "password",
      inputPlaceholder: "Ingrese pin de acceso para salir",
      inputAttributes: {
        autocapitalize: "off"
      },
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      preConfirm: pin => {
        if (!this.pinValidate(pin)) {
          Swal.showValidationMessage(`Pin incorrecto!`);
          return false;
        } else {
          this.Auth.logout().subscribe((res:any) => {
            this.toastr.info("Cerrando sesión..", "", {
              timeOut: 1000
            });
          });
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 1200);
          return true;
        }
      }
    }).then(result => {
      return false;
    });
  }

  private pinValidate(pin:number) {
    const userPin: number = Constant.AUTH.getUser().pin;
    if (pin == userPin) {
      return true;
    }
    return false;
  }



  public getUser() 
  {
  return  Constant.AUTH.getUser();
  }
 
}
