import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Constant } from "../shared/constants";
import { GlobalService } from "src/app/services/global.service";

@Injectable({
  providedIn: "root"
})
export class AreaService {
  constructor(private globalService: GlobalService) {}

  public getAreasWithServices(): Observable<any> {
    return this.globalService
      .get(Constant.Endpoints.AREA.AREA_WITH_SERVICES)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  public getAreas(): Observable<any> {
    return this.globalService.get(Constant.Endpoints.AREA.AREA_BASE).pipe(
      map(res => {
        return res;
      })
    );
  }

  public getAreaById(id:string) {
    return this.globalService
      .get(Constant.Endpoints.AREA.AREA_BASE + "/" + id)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  

 
}
