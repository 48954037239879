import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AreaService } from 'src/app/services/area.service';
import { TurnService } from 'src/app/services/turn.service';
import { WorkingDayService } from 'src/app/services/working-day.service';
import { Constant } from 'src/app/shared/constants';
import Swal from 'sweetalert2';
import { ModalTurnComponent } from './modal-turn/modal-turn.component';

@Component({
  selector: 'app-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.scss']
})
export class AutoComponent implements OnInit {
  public form: FormGroup;
  public client:any;
  public selectedArea = false;
  selectedIndex = null;
  public time = new Date();
  public timer : any;
  public services = [];
  public areas = [];
  public clicked = false;
  public disabledButton = false;
  public load = false;
  public priority = false;
  public workingDay: any;

  constructor(
    private areaService: AreaService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private turnService: TurnService,
    private workingDayService: WorkingDayService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({ 
      service_id : [null, Validators.required],
      client_id : [null, Validators.required],
      priority : [false, Validators.required]
    });
  }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);

    this.onLoad();

    

    console.log(this.client);
    
    this.timer = setInterval(() => {
      this.time = new Date();
    }, 1000);
  
  }
 
  public onTabChanged(event:any) {
      this.clicked = true;
    
  }
getUser(){
  return JSON.parse(localStorage.getItem('user')!) ;
}
  public generateTurn(serviceId:number) {
   
    this.form.controls['client_id'].setValue(this.client?.id);
    this.form.controls['client_id'].updateValueAndValidity();
    this.form.controls['service_id'].setValue(serviceId);
    this.form.controls['service_id'].updateValueAndValidity();
    
    if (!this.form.valid) {
      let err = { form: this.form.value, client: this.client}
      this.onFailure
      Swal.fire("Falta Algo", 'Hola, algo ocurrio y debemos empezar de nuevo', 'error');
         this.selectedArea = false;
      this.client = null;
      return;
    }
   
    this.disabledButton = true;
    this.turnService.turnPost(this.form.value).subscribe(
      (res : any) => {
      
        this.openTurnModal(res.name, this.client.fullname, this.client.identification_number, res.service?.name);
        this.form.controls["priority"].setValue(false);
      },
      (err : any) => {
        this.onFailure(err);
      }
    );
  }

  public logout() {
    this.messageSwal(true);
  }

  public priorityClick() {

  }

  private getAreasWithService() {
    this.areaService.getAreasWithServices().subscribe((res : any) => {
      this.areas = res.data ? res.data : res;
      
    }, (err : any) => {

    }
    );
  }

  private messageSwal(logout = false) {
   
     Swal.fire({
      title: "Pin de Seguridad",
      input: "password",
      inputPlaceholder: "Ingrese pin de acceso",
      inputAttributes: {
        autocapitalize: "off"
      },
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      preConfirm: pin => {
        if (!this.pinValidate(pin)) {
          Swal.showValidationMessage(`Pin incorrecto!`);
          return false;
        } else {
          if (logout) {
            this.toastr.info("Cerrando sesión..", "", {
              timeOut: 1000
            });
            localStorage.clear()
            setTimeout(() => {
              this.router.navigate(["/login"]);
            }, 1200);
          }
          return true;
        }
      }
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        this.priority = false;
      }
      return false;
    }); 
  }

  private onFailure(err:any) {
    let errorString = err.errors? err.errors.toString() : "Error Inesperado";
    Swal.fire(err.codetext, errorString, "error");
    this.services = [];
    this.selectedArea = false;
    this.client = null
    this.priority = false;
    this.clicked = false;
    this.selectedIndex = null;
    this.disabledButton = false;
  }

  private onLoad() {
    this.workingDayService.getWorkingDayToday().subscribe((res: any) => {
      this.workingDay = res?.data;
      this.load = true;
      if (this.workingDay !== null) {
        this.getAreasWithService();
      }
    });
  }

  private openTurnModal(res: string, client : string, nit: string, service : string) {
    const modalRef = this.modalService.open(ModalTurnComponent, {
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.title = res;
    modalRef.componentInstance.client = client;
    modalRef.componentInstance.nit = nit;
    modalRef.componentInstance.service = service;
    modalRef.result.then(() => {
      this.services = [];
      this.selectedArea = false;
      this.client = null
      this.priority = false;
      this.clicked = false;
      this.selectedIndex = null;
      this.disabledButton = false;
    }, this.onFailure);
  }

  private pinValidate(pin:number) {
    const userPin: number = Constant.AUTH.getUser().pin;
    if (pin == userPin) {
      return true;
    }
    return false;
  }
  closeSearch(event:any) {
    console.log("close search");
    
    this.client = event;
    this.form.controls['client_id'].setValue(event.id);
    if (this.areas.length == 0) {
      this.getAreasWithService();
    }
    this.selectedArea = false;
  
  }
  closedAreas(event:any) {
  
    if (event.close) {
      this.client = null;
      this.selectedArea = false;
      this.services  = [];
    }else{
      this.selectedArea = true;
      this.services  = event.services;
    }
    
  }
  closedServices(event:any) {
    
   if (event.createdTurn) {
     this.generateTurn(event.service_id);
   }
   if (event.close) {
    this.selectedArea = false;
    this.services  = [];
  }
  }
  emiterError(event:any){
  }

  
}