import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor() {
  }
  getNow(){
    let n = localStorage.getItem('now');
    if (n){
    return new Date(n);
    }    
    return new Date();
  }

  public getDateFormatted(date: Date) {
    let month = date.getMonth() + 1;
    const months = month < 10 ? "0" + month : month;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return `${date.getFullYear()}-${months}-${day}`;
  }
 

  secondsToStringFormat(secondsQuantity: number){
    let timer = '';
    let hours = '00';
    let minutes = '00';
    if (secondsQuantity > 3600) {
      let diffH = Math.trunc((secondsQuantity/ 3600));
     
      hours = diffH >= 10 ? diffH.toString() : '0' + diffH.toString();
      secondsQuantity = secondsQuantity - (diffH *3600);
    }
    if (secondsQuantity > 60) {
      let diffM = Math.trunc((secondsQuantity/ 60));
      minutes = diffM >= 10 ? diffM.toString() : '0' + diffM.toString();
      secondsQuantity = secondsQuantity - (diffM *60);
    }
    
    let seconds = secondsQuantity >= 10 ? secondsQuantity.toString() : '0' + secondsQuantity.toString();
   
   timer = hours + ':' + minutes + ':' +seconds;
   return timer;
  }

}
