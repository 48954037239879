import { Component } from '@angular/core';
import { TenancyConfigService } from './services/tenancy-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private config : TenancyConfigService) {
    this.config.PublicGet().subscribe(
      (res : any )  => {
        console.log(res);
        localStorage.setItem("config", JSON.stringify(res) )
      }
    )
  }
}
