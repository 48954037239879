import { Injectable } from "@angular/core";
import { GlobalService } from "./global.service";
import { Observable } from "rxjs";
import { Constant } from "../shared/constants";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class TenancyConfigService {
  constructor(private globalService: GlobalService, private http: HttpClient) {}

 

  public get(): Observable<any> {
    return this.http.get(Constant.Endpoints.TENANCY_CONFIG.BASE).pipe(
      map(res => {
        return res;
      })
    );
  }

  public PublicGet(): Observable<any> {
    return this.globalService
      .get(Constant.Endpoints.TENANCY_CONFIG.PUBLIC_GET)
      .pipe(
        map(res => {
          localStorage.setItem("config", JSON.stringify(res));
          return res;
        })
      );
  }

  


}
