import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Constant } from "../shared/constants";
import { GlobalService } from "src/app/services/global.service";

@Injectable({
  providedIn: "root"
})
export class WorkingDayService {
  constructor(private globalService: GlobalService) {}

  public getWorkingDayToday(): Observable<any> {
    return this.globalService.get(Constant.Endpoints.WORKING_DAY.TODAY).pipe(
      map(res => {
        return res;
      })
    );
  }


}
