import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
import { AuthService } from '../services/auth.service';
import { Constant } from '../shared/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public formLogin: any;
  public loading = false;
  public passMinLegth = 6;
  public submitted = false;
  public user : any;
  modalRef : any;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.user = Constant.AUTH.getUser();
    if (this.user && this.user?.usertypes?.length >= 1 ) {
           
      this.router.navigate(["/auto"]);
     
    }

    
    this.formLogin = this.formBuilder.group({
      email: ["", Validators.required],
      password: [
        "",
        [Validators.required, Validators.minLength(this.passMinLegth)]
      ]
    });
  }

  get f() {
    return this.formLogin.controls;
  }

  onLoginEmailPassword() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("userTypeId");
    
    this.submitted = true;
    if (this.formLogin.invalid) {
      return;
    }
    this.loading = true;
    this.authenticationService
      .login(this.formLogin.value)
      .subscribe(
        () => {
          this.user = Constant.AUTH.getUser();
          if (this.user) {
            this.onSuccess();
          }else{
            this.onFailure("no podemos leer el usuario, contacta con soporte");
          }
          
        },
        (err : any) => {
          this.onFailure("El usuario o la contraseña son incorrectas o falta verificar tu cuenta!", err);
        }
      );
  }

  public onSuccess() {
    //validar tipo de usuario
    console.log(this.user.usertypes);
    
    let autoType = this.user?.usertypes?.find((f :any) => f.id == 3 );
    console.log(autoType);
    
    if (autoType) {
    
      this.router.navigate(["/auto"]);
     
    }
    
  }

  public onFailure(sms : string, err = null) {
    this.loading = false;
    Swal.fire(sms,"Atencion", "error");
    
  }

  public resetPassword() {
    this.router.navigateByUrl("/reset-password");
  }

}
