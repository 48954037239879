<div class="container-fluid body">
  <div class="row">
    <div
      class="col-md-12 text-center position-relative"
      style="margin: 0 !important"
    >
      <h1 class="title">Bienvenido {{ client ? client.firstname : "" }}</h1>
      <h4 class="titlev2">Selecciona el Área</h4>
    </div>
    <div  class="row rowNormal" style="max-height: 55vh;overflow-y: auto;">
      <div class="col-md-6" *ngFor="let area of areas; let i = index">
        <button
          (click)="selectArea(area)"
          class="btn btn-lg btn-block ml-5 buttonClass"
          [ngClass]="i % 2 == 0 ? 'aling-left' : 'aling-rigth'"
        >
          <span>
            <img src="./assets/img/iconEmpleado.png" style="    width: 56px;">
            <!-- <i
              style="color: white"
              class="fa {{ area.icon | lowercase }} ml-2 icon"
            ></i> -->
          </span>
          <p class="textoCompae">

            {{ area.name | titlecase }}
          </p>
        </button>

    </div>
    </div>
    <div class="scrollResp">
      <div class="col-md-6" *ngFor="let area of areas; let i = index">
        <button
          (click)="selectArea(area)"
          class="btn btn-lg btn-block ml-5 buttonClass"
          [ngClass]="i % 2 == 0 ? 'aling-left' : 'aling-rigth'"
       
        >
          <span
            ><i
              style="color: white"
              class="fa {{ area.icon | lowercase }} ml-2 icon"
            ></i
          ></span>
        <span class="textoCompae">

          {{ area.name | titlecase }}
        </span>
        </button>

    </div>
    </div>
  </div>
</div>
<button class="btn volver btn-outline-primary" (click)="close()">
  <i class="fa fa-arrow-left" aria-hidden="true"></i> Volver
</button>
