import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choose-service',
  templateUrl: './choose-service.component.html',
  styleUrls: ['./choose-service.component.scss']
})
export class ChooseServiceComponent implements OnInit {
  @Input() services :Array<any> = [];
  @Output() closedServices: EventEmitter<any> = new EventEmitter<any>();
  @Output() emiterError: EventEmitter<any> = new EventEmitter<any>();
  @Input() client :any = null;
  public disabledButton = false;
  constructor() { 
    
    
  }

  ngOnInit(): void {
  }
  generateTurn(service_id:string) {
    this.disabledButton = true;
    this.closedServices.emit({createdTurn : true, service_id: service_id});
  }
  close()
  {
    this.closedServices.emit({close:true});
  }
}
