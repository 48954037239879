import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { LoginModel } from '../models/LoginModel.interface';
import { Constant } from '../shared/constants';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private globalService : GlobalService) { }
  login(data: LoginModel): Observable<any> {
    return this.globalService
      .post(Constant.Endpoints.AUTH.LOGIN, {
        grant_type: "password",
        email: data.email,
        password: data.password,
        remember_me: true
      })
      .pipe(
        map((res : any) => {
          localStorage.setItem("token", res.data?.access_token);
          localStorage.setItem("user", JSON.stringify(res.data?.user));
          return res;
        })
      );
  }
  isLoggedIn() {
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      return true;
    }
    return false;
  }
  logout(): Observable<any> {
    return this.globalService.get(Constant.Endpoints.AUTH.LOGOUT).pipe(
      map(res => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("userTypeId");
        return res;
      })
    );
  }

}
