import Swal from "sweetalert2";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Constant } from "../shared/constants";


@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  private checkLogin(url: string) {
    if (this.authService.isLoggedIn()) {
      const userTypes = Constant.AUTH.getUserTypes();
      let auto = userTypes.find(f => f.id == Constant.USERTYPESENUM.AutoGestion.id);
      if (auto){
        return true;
      }else{
        this.authService.logout();
        this.router.navigate(["/"]);
        return false;
      }
         
    } else {
    
      this.router.navigate(["/"]);
      return false;
    }
  }

}
