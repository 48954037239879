import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {  NgxPrintModule } from 'ngx-print';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './services/auth.guard';
import { InterceptRequestsService } from './services/intercept-requests.service';
import { LoginComponent } from './login/login.component';
import { ModalTurnComponent } from './components/auto/modal-turn/modal-turn.component';
import { AutoComponent } from './components/auto/auto.component';
import { ChooseAreaComponent } from './components/auto/choose-area/choose-area.component';
import { ChooseServiceComponent } from './components/auto/choose-service/choose-service.component';
import { FooterComponent } from './components/auto/footer/footer.component';
import { NavbarComponent } from './components/auto/navbar/navbar.component';
import { SearchIdentificationComponent } from './components/auto/search-identification/search-identification.component';
import { WorkingDayCloseComponent } from './components/auto/working-day-close/working-day-close.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AutoComponent,
    ModalTurnComponent,
    ChooseServiceComponent,
    ChooseAreaComponent,
    SearchIdentificationComponent,
    WorkingDayCloseComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    MatSlideToggleModule,
    NgxPrintModule,
    NgxQRCodeModule
  ],
  providers: [AuthGuard,
    InterceptRequestsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptRequestsService,
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
