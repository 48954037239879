<!-- <app-navbar></app-navbar>
<div class="container-fluid body">
  <form [formGroup]="form">


  <div class="row">
    <div class="col-2"></div>
    <div class="col-8 text-center pt-3">
      <h4 class="text-center title"> Bienvenido {{client?.fullname}} </h4>
    </div>
    <div class="col-2">
      <mat-slide-toggle
      *ngIf="selectedArea"
        class="mt-3"
        style="font-size: 20px; color: white;"
        formControlName="priority"
        (change)="priorityClick()"
        >Prioritario</mat-slide-toggle
      >
    </div>
  </div>
  <div *ngIf="workingDay && load">
    <app-search-identification *ngIf="!client" (closeSearch)="closeSearch($event)" (emiterError)="emiterError($event)"> </app-search-identification>
    <app-choose-area *ngIf="client && !selectedArea" [areas]="areas" (closedAreas)="closedAreas($event)" (emiterError)="emiterError($event)"></app-choose-area>
    <app-choose-service *ngIf="client && selectedArea" [services]="services" (closedServices)="closedServices($event)" (emiterError)="emiterError($event)">
   
   </app-choose-service>
   
  </div>

</form>
<app-working-day-close
  class="col-8"
  *ngIf="!workingDay && load"
  style="position: absolute; top: 10%; right: 17%;"
></app-working-day-close>
</div> -->
  <form [formGroup]="form">
    <div class="fondo" style="
    background-image: url(./assets/img/tramalogo.png);
    right: -141px;
    z-index: -33;
    bottom: -319px;
    width: 900px;
    background-size: 84em 100%;
    height: 900px;
  "></div>
<div class="row">
  <div class="col" id="ColImage">
    <img src="./assets/img/descarga.png" class="image" id="ImageIcon" />
  </div>
</div>
<div class="row" style="margin-top: 5px">
  <div class="col-1 orange"></div>
  <div class="col-1 orange_figure"></div>
  <div class="col other_line"></div>
</div>
<div class="row mt-6" style="height: 67vh; align-items: center" id="RowId">
  <div *ngIf="workingDay && load">
    <app-search-identification *ngIf="!client" (closeSearch)="closeSearch($event)" (emiterError)="emiterError($event)">
    </app-search-identification>
    <app-choose-area *ngIf="client && !selectedArea" [client]="client" [areas]="areas"
      (closedAreas)="closedAreas($event)" (emiterError)="emiterError($event)"></app-choose-area>
    <app-choose-service *ngIf="client && selectedArea" [client]="client" [services]="services"
      (closedServices)="closedServices($event)" (emiterError)="emiterError($event)">
    </app-choose-service>
  
    <!-- <div class="col">hola</div>
  <div class="col">hola</div>
  <div class="col">hola</div> -->
  </div>
</div>
<div class="subidaEdge">
  <mat-slide-toggle
  *ngIf="selectedArea"
    class="mt-3"
    style="font-size: 20px; color: black;    font-weight: bold;"
    formControlName="priority"
    (change)="priorityClick()"
    ><p style="font-size: 20px; color: black;    font-weight: bold;">Prioritario</p></mat-slide-toggle
  >
</div>
</form>
<button class="botonF1" (click)="logout()">
  <span>
    <i class="fa fa-sign-out" aria-hidden="true"></i>
  </span>
  
</button>