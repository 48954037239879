<div  class="container-fluid mt-3" >
  <h1 style="text-align: center; padding: 30px; margin-bottom: 10px; color: white;">
    Appturnos
  </h1>

  <div style="text-align: center">
    <img
      src="../../../assets/img/ticket.png"
      style=" width: 200px; padding: 30px; margin-bottom: 30px;"
      class="img-white"
    />
  </div>

  <div class="alert alert-warning text-center" role="alert" style="color: #0863a8 !important;">
    <b>Estado</b> {{message}}
  </div>
</div>
