import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Constant } from "../shared/constants";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root"
})
export class TurnService {
  constructor(private globalService: GlobalService) {}



  public turnPost(data: any): Observable<any> {
    return this.globalService
      .post(Constant.Endpoints.TURN.TURN_BASE, data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }


}
