<footer >
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <img
          src="../../../../assets/img/logoAppturno.png"
          alt="Logo AppTurno"
          style="width: 80%; margin-left: 4%; margin-bottom: 5px !important;"
        />
      </div>
      <div class="col-6">
        <p class="text-center text-muted">
          © {{ helper.getNow() | date: "yyyy" }} Copyright Todos los derechos reservados -
          AppTurno | Version : {{version}}
        </p>
      </div>
      <div class="col-3">
        <h4 style="font-size: 2rem !important;">
          {{ helper.getNow() | date: "hh:mm" | uppercase }} 
          <small>
            {{ helper.getNow() | date: " M/d/yy" | titlecase }}</small>
        </h4>
      </div>
      
    </div>
  </div>
</footer>
