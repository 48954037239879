import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { Constant } from "../shared/constants";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private globalService: GlobalService) { }


  public searchOrCreate(identification:string): Observable<any> {
    return this.globalService.get(Constant.Endpoints.CLIENT.FIND_OR_CREATE+'/'+identification).pipe(
      map(res => {
        return res;
      })
    );
  }
  getById(id:string) {
    return this.globalService.get(Constant.Endpoints.CLIENT.BASE + "/" + id).pipe(
      map(res => {
        return res;
      })
    );
  }
  public update(data : any) {
    return this.globalService
    .put(Constant.Endpoints.CLIENT.BASE + "/" + data.id, data)
    .pipe(
      map(res => {
        return res;
      })
    );
  }
}
