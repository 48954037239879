<!-- <button class="btn volver btn-outline-primary" (click)="close()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Volver</button>
<div class="container-fluid body">
    <div class="row">
        <div class="col-md-12 text-center">
            <h3 class="title">Selecciona el servicio</h3>
        </div>
        <div class="col-md-6" *ngFor="let service of services;let i=index;" >
            <button
                    [disabled]="disabledButton"

                    (click)="generateTurn(service.id)"
                    class="btn btn-lg btn-block ml-5 services"
                    [ngClass]="i % 2 == 0 ? 'aling-left' : 'aling-rigth'"

                    style="font-family:itc-kabel-std-demi"
                  >
                    <span
                      ><i style="color:#0863a8; " class="fa {{ service.icon | lowercase }} ml-2 icon"></i
                    ></span>

                    {{ service.name | titlecase }}
                  </button>

        </div>
    </div>


</div> -->
<div class="container-fluid body">
  <div class="row">
    <div
      class="col-md-12 text-center position-relative"
      style="margin: 0 !important"
    >
      <h1 class="title">Bienvenido {{ client ? client.firstname : "" }}</h1>
      <h4 class="titlev2">Selecciona el Servicio</h4>
    </div>
    <div class="row rowNormal" style="max-height: 55vh;overflow-y: auto;">
      <div class="col-md-6" *ngFor="let area of services; let i = index">
        <button
          (click)="generateTurn(area.id)"
          class="btn btn-lg btn-block ml-5 buttonClass" 
          [ngClass]="i % 2 == 0 ? 'aling-left' : 'aling-rigth'">
          <span> 
            <img src="./assets/img/iconEmpleado.png" style="    width: 56px;">
          </span>
          <p class="textoCompae">
          {{ area.name | titlecase }}
          <p>
        </button>
      </div>
    </div>

    <div class="scrollResp">
      <div class="col-md-6" *ngFor="let area of services; let i = index">
        <button
          (click)="generateTurn(area.id)"
          class="btn btn-lg btn-block ml-5 buttonClass"
          [ngClass]="i % 2 == 0 ? 'aling-left' : 'aling-rigth'"
        >
          <span>
            <img src="./assets/img/iconEmpleado.png" style="    width: 56px;">

          </span>
          <p class="textoCompae">
            {{ area.name | titlecase }}
            <p>
        </button>
      </div>
    </div>

  </div>
</div>
<button class="btn volver btn-outline-primary" (click)="close()" style="width: 260px;height: 60px;">
  <i class="fa fa-arrow-left" aria-hidden="true"></i> Volver
</button>
